import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "./link";

import Certificazioni from "../components/certificazioni";

import Logo from "../svg/ariadne-logo.svg";
import AriadneGroup from "../svg/ariadne-group-logo.svg";
import AriadneLogoOutline from "../svg/logo-simbolo-outline-ariadne.svg";
import Square from "../svg/square-footer.svg";

const Footer = () => {
  const { navigationJson } = useStaticQuery(
    graphql`
      query {
        navigationJson {
          footer {
            name
            link
          }
        }
      }
    `
  );

  return (
    <>
      <Certificazioni />
      <footer className="footer">
        <AriadneLogoOutline className="logo-outline" />
        <div className="footer__group-top">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                Ariadne è parte di <strong>Ariadne Group</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__group-bottom">
          <a
            href="https://www.ariadnegroup.it"
            title="Ariadne Group - the Digital Compass"
            className="footer__group-bottom-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="invisible">Vai al sito Ariadne Group</span>
          </a>
          <div className="container">
            <div className="row">
              <div className="col-md-4 d-flex justify-content-center justify-content-md-start align-items-md-center">
                <AriadneGroup className="ariadne-group-logo" />
              </div>
              <div className="col-md-4 d-flex justify-content-center align-items-md-center my-4 my-md-0">
                <div>
                  the <strong className="text-uppercase">Digital</strong>{" "}
                  <span className="text-uppercase">Compass</span>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center justify-content-md-end align-items-md-center">
                <span className="btn-link mt-0">vai al sito</span>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <Square className="square" />
          <div className="container">
            <div className="row">
              <div className="col-xl-11 offset-xl-1">
                <div className="row mb-3 mb-xl-5">
                  <div className="col-md-6">
                    <div className="logo">
                      <Logo className="logo__img mb-3" />
                    </div>
                    <div>
                      Ariadne S.r.l. a socio unico
                      <br />
                      C.F. e Registro Imprese: 016337010189
                      <br />
                      Partita IVA: 13374030156
                      <br />
                      Iscritta al Registro Imprese di Milano
                      <br />
                      REA: MI-1643610 - Cap. Soc. 50.000,00 € i.v.
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="footer__sede-label">Sede legale</div>
                        <div>Via Visconti di Modrone, 11</div>
                        <div>20122 Milano</div>
                      </div>
                      <div className="col-sm-6">
                        <div className="footer__sede-label">Sede operativa</div>
                        <div>Via Giovanni Perotti, 4</div>
                        <div>27020 Trivolzio, Pavia</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Link
                      to="/contatti/"
                      className="btn-link mb-3"
                      activeClassName="btn-link--active"
                      partiallyActive={true}
                    >
                      Contattaci
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <div className="footer__contact-title">Get in touch</div>
                    <div className="row">
                      <div className="col-sm-6 col-md-12 col-lg-6">
                        <a
                          href="mailto:info@ariadne.it"
                          title="Scrivici"
                          rel="noopener noreferrer"
                          className="footer__contact"
                        >
                          info@ariadne.it
                        </a>
                      </div>
                      <div className="col-sm-6 col-md-12 col-lg-6">
                        <a
                          href="tel:+390382408911"
                          title="Chiamaci"
                          rel="noopener noreferrer"
                          className="footer__contact"
                        >
                          +39 0382 408911
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <a
                            href="https://www.linkedin.com/company/ariadnegroup/"
                            title="LinkedIn Ariadne Group"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer__social"
                          >
                            LinkedIn
                          </a>
                        </div>
                        <div>
                          <a
                            href="https://twitter.com/AriadneGroup"
                            title="Twitter Ariadne Group"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer__social"
                          >
                            Twitter
                          </a>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <a
                            href="https://www.facebook.com/AriadneGroup"
                            title="Facebook Ariadne Group"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer__social"
                          >
                            Facebook
                          </a>
                        </div>
                        <div>
                          <a
                            href="https://www.youtube.com/channel/UC7_Z0F7wvMmzuYxa0OAq-Zg"
                            title="YouTube Ariadne Group"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer__social"
                          >
                            YouTube
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className="footer__list d-flex justify-content-md-center flex-wrap">
          <li className="footer__item">2022 &copy; Tutti i diritti riservati</li>
          {navigationJson.footer.map((item, i) => (
            <li key={i} className="footer__item">
              <Link
                className="footer__link"
                activeClassName="footer__link--active"
                partiallyActive={true}
                to={item.link}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </footer>
    </>
  );
};

export default Footer;
