import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Certificazioni = () => {
  const { AWSPartner, liferayCommerce, liferayPlatinum, salesforce, sqsIqnet } =
    useStaticQuery(graphql`
      {
        AWSPartner: file(relativePath: { eq: "certificazioni/AWSPartner-2022.png" }) {
          childImageSharp {
            gatsbyImageData(width: 300, layout: CONSTRAINED)
          }
        }
        liferayCommerce: file(relativePath: { eq: "certificazioni/liferay-commerce.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 400, layout: CONSTRAINED)
          }
        }
        liferayPlatinum: file(relativePath: { eq: "certificazioni/liferay-platinum-partner.png" }) {
          childImageSharp {
            gatsbyImageData(width: 400, layout: CONSTRAINED)
          }
        }
        salesforce: file(relativePath: { eq: "certificazioni/salesforce-certified-logo.png" }) {
          childImageSharp {
            gatsbyImageData(width: 150, layout: CONSTRAINED)
          }
        }
        sqsIqnet: file(relativePath: { eq: "certificazioni/cert-marks-system-sqs-iqnet.png" }) {
          childImageSharp {
            gatsbyImageData(width: 340, layout: CONSTRAINED)
          }
        }
      }
    `);
  return (
    <>
      <section className="section section--white">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="section__title section__title--small text-center">Certificazioni</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex flex-column flex-lg-row justify-content-between align-items-center">
              <div className="me-0 me-lg-5">
                <GatsbyImage
                  image={getImage(liferayPlatinum)}
                  alt="Liferay Platinum Partner - logo"
                />
              </div>
              <div className="me-0 me-lg-5">
                <GatsbyImage
                  image={getImage(liferayCommerce)}
                  alt="Liferay Commerce Partner - logo"
                />
              </div>
              <div className="me-0 me-lg-5 mb-5 mb-lg-0">
                <GatsbyImage image={getImage(salesforce)} alt="Salesforce Certified - logo" />
              </div>
              <div className="me-0 me-lg-5 mb-5 mb-lg-0">
                <GatsbyImage image={getImage(AWSPartner)} alt="Amazon Web Services - logo" />
              </div>
              <div className="me-0 me-lg-5">
                <GatsbyImage
                  image={getImage(sqsIqnet)}
                  alt="IQNET / SQS - Certified Management System"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Certificazioni;
