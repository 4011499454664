import React from "react";

import AriadneLogoOutline from "../svg/logo-simbolo-outline-ariadne.svg";
import Arrow from "../svg/square-arrow.svg";
import Square from "../svg/square.svg";
import SquareMulti from "../svg/square-multi-hero.svg";

const Hero = ({ content }) => {
  return (
    <>
      {content.map((item, i) => (
        <div key={i} className={`hero${item.className ? ` ${item.className}` : ""}`}>
          <Square className="square-single d-none d-md-block" />
          <SquareMulti className="square-multi d-none d-md-block" />
          <AriadneLogoOutline className="logo-outline" />
          <div className="hero__square">
            {item.className === "hero--big" && <Arrow className="hero__arrow" />}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-8">
                <div className="hero__text">
                  <h1 className="hero__title">{item.title}</h1>
                  {item.subject && <h2 className="hero__subject">{item.subject}</h2>}
                  {item.description && <div className="hero__description">{item.description}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Hero;
