import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const formatUrl = (src) => (src && src.startsWith("https") ? src : `https://www.ariadne.it${src}`);

const normalize = (image) => {
  if (!image) return image;
  if (image.gatsbyImageData) {
    const { images } = image.gatsbyImageData;
    const src = images.fallback.src;
    const params =
      typeof URLSearchParams === "function" ? new URLSearchParams(src.split("?")[1]) : null;
    return {
      src: formatUrl(src),
      width: params ? params.get("w") : null,
      height: params ? params.get("h") : null,
    };
  }
  if (image.resize) {
    return image.resize;
  }
};

const Seo = ({ description, lang, meta, keywords, title, img }) => {
  const { site, defaultogimage } = useStaticQuery(
    graphql`
      query DefaultSeoQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        defaultogimage: file(relativePath: { eq: "share-img.png" }) {
          childImageSharp {
            gatsbyImageData(height: 630, layout: CONSTRAINED)
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const ogimg = img?.childImageSharp || img || defaultogimage.childImageSharp;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(
          ogimg
            ? [
                {
                  property: `og:image`,
                  content: normalize(ogimg).src,
                },
                {
                  property: `twitter:image`,
                  content: normalize(ogimg).src,
                },
              ]
            : []
        )
        .concat(meta)}
    />
  );
};

Seo.defaultProps = {
  lang: `it`,
  meta: [],
  keywords: [],
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default Seo;
