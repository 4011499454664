import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import Breadcrumb from "./breadcrumb";
import Hero from "./hero";
import Footer from "./footer";

const Layout = ({ hero, breadcrumb, children }) => {
  const { site } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <div className="wrapper">
      <Header siteTitle={site.siteMetadata.title} />
      {hero ? <Hero content={hero} /> : null}
      {breadcrumb ? <Breadcrumb list={breadcrumb} /> : null}
      {children}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
