import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import pThrottle from "p-throttle";
import classNames from "classnames";
import { Link } from "./link";

import Logo from "../svg/ariadne-logo.svg";
import AriadneLogoOutline from "../svg/logo-simbolo-outline-ariadne.svg";
import Arrow from "../svg/arrow.svg";
import ArrowDown from "../svg/arrow-down.svg";

const Header = ({ siteTitle }) => {
  const { navigationJson } = useStaticQuery(
    graphql`
      query {
        navigationJson {
          header {
            name
            link
            children {
              name
              desc
              link
              children {
                name
                link
              }
            }
          }
        }
      }
    `
  );

  const [open, setOpen] = useState(false);
  const toggleMenu = useCallback(() => setOpen((value) => !value), []);

  const [openSub, setOpenSub] = useState(false);
  const toggleMenuSub = useCallback(() => setOpenSub((value) => !value), []);

  const [openMegaMenu, setOpenMegaMenu] = useState(false);
  const onMouseEnter = (e) => {
    if (e.currentTarget.getAttribute("data-children") === "true") {
      setOpenMegaMenu(true);
    }
  };
  const onMouseLeave = (e) => {
    if (e.currentTarget.getAttribute("data-children") === "true") {
      setOpenMegaMenu(false);
    }
  };

  const [headerScroll, setHeaderScroll] = useState(false);

  useEffect(() => {
    const throttle = pThrottle({
      limit: 2,
      interval: 100,
    });
    const listener = throttle(() => {
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        setHeaderScroll(true);
      } else {
        setHeaderScroll(false);
      }
    }, 100);
    document.addEventListener("scroll", listener);
    listener();
    return () => document.removeEventListener("scroll", listener);
  }, []);

  return (
    <div
      className={classNames("header", {
        "header--scroll": headerScroll,
        "header--openmenu": openMegaMenu,
      })}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-xl-flex justify-content-xl-between align-items-xl-center">
              <div className="d-flex align-items-center justify-content-between">
                <div className="logo">
                  <Link className="logo__link" to="/">
                    <Logo className="logo__img" />
                    <span className="d-none">{siteTitle}</span>
                  </Link>
                </div>
                <button
                  className={`hamburger d-xl-none${open ? " open" : ""}`}
                  aria-label="menu"
                  onClick={toggleMenu}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
              <div className={`navigation${open ? " open" : ""}`}>
                <div>
                  <ul className="navigation__list">
                    {navigationJson.header.map((item, i) => (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                      <li
                        key={i}
                        className="navigation__item"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        data-children={item.children ? "true" : "false"}
                      >
                        <Link
                          to={item.link}
                          title={item.name}
                          className="navigation__link"
                          activeClassName="navigation__link--active"
                          partiallyActive={true}
                        >
                          {item.name}
                        </Link>
                        {item.children && (
                          <>
                            <ArrowDown className="navigation__arrow d-none d-xl-inline-block" />
                            <button
                              type="button"
                              onClick={toggleMenuSub}
                              title="Apri/Chiudi sottomenu"
                              aria-label="Apri/Chiudi sottomenu"
                              className="navigation__toggle d-xl-none"
                            ></button>
                            <div className={`megamenu${openSub ? " open" : ""}`}>
                              <div className="d-xl-flex justify-content-xl-center">
                                <AriadneLogoOutline className="logo-outline d-none d-xl-block" />
                                <div className="megamenu__square d-none d-xl-block"></div>
                                <div className="megamenu__wrap d-xl-flex">
                                  {item.children.map((child, i) => (
                                    <div key={i} className="megamenu__box">
                                      <div className="megamenu__father d-none d-xl-block">
                                        {item.name}
                                      </div>
                                      <Link
                                        to={child.link}
                                        title={child.name}
                                        className="megamenu__link"
                                        activeClassName="megamenu__link--active"
                                        partiallyActive={true}
                                      >
                                        {child.name}
                                        <Arrow className="d-none d-xl-inline-block" />
                                      </Link>
                                      {child.desc && (
                                        <div className="megamenu__desc d-none d-xl-block">
                                          {child.desc}
                                        </div>
                                      )}
                                      {child.children && (
                                        <div>
                                          {child.children.map((sub, j) => (
                                            <div key={j}>
                                              <Link
                                                to={sub.link}
                                                title={sub.name}
                                                className="megamenu__sub-link"
                                                activeClassName="megamenu__sub-link--active"
                                                partiallyActive={true}
                                              >
                                                {sub.name}
                                              </Link>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <Link
                className="d-none d-xl-block btn-link"
                activeClassName="btn-link--active"
                partiallyActive={true}
                to="/contatti/"
              >
                Contatti
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
