import React from "react";
import { Link } from "./link";

const Breadcrumb = ({ list }) => {
  return (
    <div className="breadcrumb" role="navigation" aria-label="breadcrumb">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ol className="breadcrumb__list">
              {list.map((item, i) => (
                <li className="breadcrumb__item" key={item.link || item.title}>
                  <div aria-level={i + 1}>
                    {i < list.length - 1 ? (
                      <Link to={item.link} title={item.title} className="breadcrumb__link">
                        {item.title}
                      </Link>
                    ) : (
                      <span className="breadcrumb__link breadcrumb__link--active">
                        {item.title}
                      </span>
                    )}
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
